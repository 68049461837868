import React from "react";

import CustomButton from "../../../shared/Button";
import { Col, Row } from "react-bootstrap";
import { OptionItem, OptionsWrapper } from "./styles";

interface RentersInsuranceProps {
  answers: (string | null)[];
  selectedAnswer: string | null;
  onAnswerSelect: (answer: string | null) => void;
  onContinueWithoutAnswer: () => void;
}

const RentersInsurance: React.FC<RentersInsuranceProps> = ({
  answers,
  selectedAnswer,
  onAnswerSelect,
  onContinueWithoutAnswer,
}) => {
  const handleOptionClick = (answer: string | null) => {
    onAnswerSelect(answer);
  };

  return (
    <>
      <Row className="w-full">
        <Col>
          <OptionsWrapper>
            {answers.map((answer) => (
              <OptionItem
                key={answer === null ? "null" : answer}
                selected={selectedAnswer === answer}
                onClick={() => handleOptionClick(answer)}
              >
                {answer === null ? "No Selection" : answer}
              </OptionItem>
            ))}
          </OptionsWrapper>
        </Col>
      </Row>
    </>
  );
};

export default RentersInsurance;
