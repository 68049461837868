import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

import PoppinsRegular from "../assets/fonts/Poppins/Poppins-Regular.woff";
import PoppinsMedium from "../assets/fonts/Poppins/Poppins-Medium.woff";
import PoppinsSemibold from "../assets/fonts/Poppins/Poppins-SemiBold.woff";
import PoppinsBold from "../assets/fonts/Poppins/Poppins-Bold.woff";

export const FontStyles = createGlobalStyle`

  @font-face {
    font-family: 'Poppins Regular';
    src:  url(${PoppinsRegular}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins Medium';
    src:  url(${PoppinsMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins Semibold';
    src:  url(${PoppinsSemibold}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins Bold';
    src:  url(${PoppinsBold}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  .custom-progress-bar {
    background-color: #eaeaea; 
    border-radius:20px;
  }
  
  .custom-progress-bar .progress-bar {
    background-color: #08c177 !important;
    border-radius:20px;
  }
  .questions-container{
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
  .progress-container {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
  ::placeholder {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins Bold", sans-serif;
    color: #2a2a2a !important;
    line-height:72px;
  }
`;
export const BooleanQuestionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.gray_100};
  border-radius: 8px;

  @media (max-width: 780px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const BooleanOption = styled.div<{ selected: boolean }>`
  background: ${(props) => props.theme.colors.blue_200};
  color: ${(props) => props.theme.colors.blue_1000};
  border: 2px solid ${(props) => props.theme.colors.blue_200};
  border-radius: 4px;
  padding: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;

  &:hover {
    border-color: ${(props) => props.theme.colors.blue_1000};
  }
`;
