import styled from "styled-components";

export const VehicleMakesSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.gray_100};
  border-radius: 8px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const VehicleMakeItem = styled.div<{ isactive: boolean }>`
  background: ${(props) =>
    props.isactive
      ? props.theme.colors.green_1000
      : props.theme.colors.blue_200};
  color: ${(props) =>
    props.isactive ? "white" : props.theme.colors.blue_1000};
  border: 2px solid ${(props) => props.theme.colors.blue_200};
  border-radius: 4px;
  padding: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
  @media (max-width: 780px) {
    padding: 14px;
    font-size: 16px;
  }
  &:hover {
    border-color: ${(props) => props.theme.colors.blue_1000};
  }
`;
