import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaSmile } from 'react-icons/fa';
import './style.css'; // Make sure to create this CSS file for additional styling

const ThankYouPage: React.FC = () => {
  return (
    <Container fluid className="thank-you-container">
      <Row className="justify-content-center align-items-center full-height">
        <Col md={8} lg={6} className="text-center">
          <Card className="thank-you-card shadow-lg">
            <Card.Body>
              <FaSmile size={60} color="#f39c12" />
              <Card.Title className="mt-4">Thank You!</Card.Title>
              <Card.Text className="mb-4">
                Thank you for providing the information. We will contact you soon.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYouPage;