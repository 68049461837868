import { Container } from "react-bootstrap";
import HeaderProps from "../utils/interfaces";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";

const CustomLayout: React.FC<HeaderProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Container fluid className="site-layout p-0 pt-5">
        <div className="content-padding">{children}</div>
      </Container>
      <Footer />
      {/* <Footer /> */}
    </>
  );
};

export default CustomLayout;
