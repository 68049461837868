import React, { useState, useEffect } from "react";
import { Form, Image } from "react-bootstrap";
import { VehicleMakeItem, VehicleMakesSectionWrapper } from "./styles";
import vehicleData from "../../../utils/vehical.json";

// Importing images directly
import bmwLogo from "../../../../../assets/vehicles/bmw-logo.svg";
import buickLogo from "../../../../../assets/vehicles/buick-logo.png";
import cadillacLogo from "../../../../../assets/vehicles/cadillac-2dcrest.svg";
import chevroletLogo from "../../../../../assets/vehicles/chevrolet-logo.png";
import chryslerLogo from "../../../../../assets/vehicles/chrysler-logo.png";
import dodgeLogo from "../../../../../assets/vehicles/dodge-logo.png";
import defaultLogo from "../../../../../assets/vehicles/default-logo.svg";
import fordLogo from "../../../../../assets/vehicles/ford-logo.png";
import gmcLogo from "../../../../../assets/vehicles/gmc-logo.png";
import hondaLogo from "../../../../../assets/vehicles/honda-logo.png";
import hyundaiLogo from "../../../../../assets/vehicles/hyundai-logo.svg";
import jeepLogo from "../../../../../assets/vehicles/jeep-logo-green.svg";
import kiaLogo from "../../../../../assets/vehicles/kia-logo.png";
import lexusLogo from "../../../../../assets/vehicles/lexus-logo.png";
import lincolnLogo from "../../../../../assets/vehicles/lincoln-logo.png";
import nissanLogo from "../../../../../assets/vehicles/nissan-logo.svg";
import oldsmobileLogo from "../../../../../assets/vehicles/oldsmobile-logo.png";
import pontiacLogo from "../../../../../assets/vehicles/pontiac-logo.png";
import ramLogo from "../../../../../assets/vehicles/ram-logo.png";
import subaruLogo from "../../../../../assets/vehicles/subaru-logo.png";
import toyotaLogo from "../../../../../assets/vehicles/toyota-logo.png";
import volkswagenLogo from "../../../../../assets/vehicles/vw-logo.svg";

const vehicleDataTyped = vehicleData as {
  vehicle: {
    [key: string]: string[];
  };
  autoLogos: {
    [key: string]: {
      img: string;
    };
  };
};

interface VehicleMakesSectionProps {
  makes: string[];
  onMakeSelect: (make: string) => void;
  selectedMake: string;
  selectedYear: any;
}
const VehicleMakesSection: React.FC<VehicleMakesSectionProps> = ({
  makes,
  onMakeSelect,
  selectedMake,
  selectedYear,
}) => {
  const [activeMake, setActiveMake] = useState<string | null>(
    selectedMake ?? ""
  );
  const [filteredMakes, setFilteredMakes] = useState<string[]>([]);
  const [remainingMakes, setRemainingMakes] = useState<string[]>([]);

  const imageMapping: { [key: string]: string } = {
    BMW: bmwLogo,
    BUICK: buickLogo,
    CADILLAC: cadillacLogo,
    CHEVROLET: chevroletLogo,
    CHRYSLER: chryslerLogo,
    DODGE: dodgeLogo,
    FORD: fordLogo,
    GMC: gmcLogo,
    HONDA: hondaLogo,
    HYUNDAI: hyundaiLogo,
    JEEP: jeepLogo,
    KIA: kiaLogo,
    LEXUS: lexusLogo,
    LINCOLN: lincolnLogo,
    NISSAN: nissanLogo,
    OLDSMOBILE: oldsmobileLogo,
    PONTIAC: pontiacLogo,
    RAM: ramLogo,
    SUBARU: subaruLogo,
    TOYOTA: toyotaLogo,
    VOLKSWAGEN: volkswagenLogo,
    DEFAULT: defaultLogo,
  };

  useEffect(() => {
    if (selectedYear) {
      const yearMakes = vehicleDataTyped.vehicle[selectedYear.toString()] || [];
      const matchedMakes = yearMakes.filter((make) =>
        makes.map((m) => m.toLowerCase()).includes(make.toLowerCase())
      );

      const unmatchedMakes = makes.filter(
        (make) =>
          !yearMakes.map((m) => m.toLowerCase()).includes(make.toLowerCase())
      );

      setFilteredMakes(matchedMakes);
      setRemainingMakes(unmatchedMakes);
    }
  }, [selectedYear, makes]);

  const handleMakeClick = (make: string) => {
    const makeyear = make.toUpperCase();
    setActiveMake(makeyear);
    onMakeSelect(makeyear);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMake = event.target.value;
    if (selectedMake) {
      setActiveMake(selectedMake);
      onMakeSelect(selectedMake);
    }
  };

  return (
    <>
      <VehicleMakesSectionWrapper>
        {filteredMakes.map((make, index) => {
          const logoPath =
            imageMapping[make.toUpperCase()] || imageMapping["DEFAULT"];
          return (
            <VehicleMakeItem
              key={index}
              onClick={() => onMakeSelect(make)}
              isactive={make === selectedMake}
              className={make == selectedMake ? "selected" : ""}
            >
              <Image
                src={logoPath}
                fluid
                width={30}
                style={{ height: "30px" }}
              />
              {make}
            </VehicleMakeItem>
          );
        })}
      </VehicleMakesSectionWrapper>
      {remainingMakes.length > 0 && (
        <Form.Select
          aria-label="Default select example"
          className="w-100"
          onChange={handleSelectChange}
          value={activeMake ?? ""}
        >
          <option value="">Select a Make</option>
          {remainingMakes.map((make, index) => (
            <option key={index} value={make}>
              {make}
            </option>
          ))}
        </Form.Select>
      )}
    </>
  );
};

export default VehicleMakesSection;
