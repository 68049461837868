export interface Question {
  id: number;
  question: string;
}

export const questions: Question[] = [
  { id: 1, question: "Enter Zip Code" },
  { id: 2, question: "Select Your Vehicle Year" },
  { id: 3, question: "Select Your Vehicle Make" },
  { id: 4, question: "What is the model of your {year} {make}?" },
  { id: 5, question: "What's the trim level of your {year} {make} {model}" },
  { id: 6, question: "Add Second Vehicle? (Save Additional 20%)" },
  { id: 7, question: "Select Your Vehicle Year" },
  { id: 8, question: "Select Your Vehicle Make" },
  { id: 9, question: "What is the model of your {year2} {make2}?" },
  { id: 10, question: "What's the trim level of your {year2} {make2} {model2}" },
  { id: 11, question: " Have you had auto insurance in the past 30 days?" },
  { id: 12, question: " Current Auto Insurance" },
  { id: 13, question: "How long have you continuously had auto insurance?" },
  { id: 14, question: " Gender  " },
  { id: 15, question: " Married?  " },
  {
    id: 16,
    question: " What's the highest level of education you've completed?",
  },
  {
    id: 17,
    question: "What is your employment status?",
  },
  { id: 18, question: " Are you a homeowner?" },
  {
    id: 19,
    question: "Include a renters insurance quote and save? No extra questions!",
  },
  {
    id: 20,
    question: "Have you had any driving incidents in the last 3 years? ",
  },
  {
    id: 21,
    question: "   Has anyone on this policy had: ",
  },
  {
    id: 22,
    question:
      " Are either you or your spouse an active member, or an honorably discharged veteran of the US military?    ",
  },
  { id: 23, question: "Birthdate  " },
  { id: 24, question: "" },
  { id: 25, question: "" },
];
