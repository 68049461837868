import React , { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import CustomLayout from "./views/components/layout/main";
import { Theme } from "./globals/theme";
import { FontStyles } from "./globals/global";
import { UserProvider } from "./context/UserContext";
import Loading from "./views/components/pages/main/loader"

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={Theme}>
        <FontStyles />
        <BrowserRouter>
        <UserProvider>
          <CustomLayout>
          <Suspense fallback={<Loading/>}>
            <App />
          </Suspense>
          </CustomLayout>
        </UserProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

reportWebVitals();
