import styled from "styled-components";

export const HomeOwnerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.gray_100};
  border-radius: 8px;
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
  }
`;

export const HomeOption = styled.div<{ selected: boolean }>`
  background: ${(props) =>
    props.selected
      ? props.theme.colors.green_1000
      : props.theme.colors.blue_200};
  color: ${(props) =>
    props.selected ? props.theme.colors.white : props.theme.colors.blue_1000};
  border: 2px solid
    ${(props) =>
      props.selected
        ? props.theme.colors.green_1000
        : props.theme.colors.blue_200};
  border-radius: 4px;
  padding: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%;
  &:hover {
    border-color: ${(props) => props.theme.colors.blue_1000};
  }
`;
