import React, { useState } from "react";
import {
  BooleanOption,
  BooleanQuestionWrapper,
} from "../../../../../../src/globals/global";

interface GenderProps {
  answers: string[];
  onSelect: (answer: string) => void;
  selectedGender: string;
}

const Gender: React.FC<GenderProps> = ({ answers, onSelect , selectedGender}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(selectedGender);

  const handleOptionClick = (answer: string) => {
    setSelectedAnswer(answer);
    onSelect(answer);
  };

  return (
    <BooleanQuestionWrapper>
      {answers.map((answer) => (
        <BooleanOption
          key={answer}
          selected={selectedAnswer === answer}
          className={selectedAnswer === answer ? 'selected':''}
          onClick={() => handleOptionClick(answer)}
        >
          {answer}
        </BooleanOption>
      ))}
    </BooleanQuestionWrapper>
  );
};

export default Gender; // Updated the export to Gender
