import React, { useState } from "react";
import {
  BooleanOption,
  BooleanQuestionWrapper,
} from "../../../../../../src/globals/global"; // Adjust the path as necessary

interface MilitarySpouseProps {
  onSelect: (option: string) => void;
  selectedSpouse:string;
}

const MilitarySpouse: React.FC<MilitarySpouseProps> = ({ onSelect ,selectedSpouse }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(selectedSpouse ?? '');

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <BooleanQuestionWrapper>
      {["Yes", "No"].map((option) => (
        <BooleanOption
          key={option}
          selected={selectedOption === option}
          className={selectedOption === option ?'selected':''}
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </BooleanOption>
      ))}
    </BooleanQuestionWrapper>
  );
};

export default MilitarySpouse;
