import React from "react";
import { InsurancePackageItem, InsurancePackageSectionWrapper } from "./styles";

interface InsurancePackage {
  name: string;
}

interface CurrentInsurancePackageProps {
  packages: InsurancePackage[];
  onPackageSelect: (packageName: string) => void;
  selectedPacakge:string;
}

const CurrentInsurancePackage: React.FC<CurrentInsurancePackageProps> = ({
  packages,
  onPackageSelect,
  selectedPacakge
}) => {
  console.log(selectedPacakge);
  return (
    <InsurancePackageSectionWrapper>
      {packages.map((pkg, index) => (
        <InsurancePackageItem
        className={ selectedPacakge == pkg.name ? 'selected' : ''}
          key={index}
          onClick={() => onPackageSelect(pkg.name)}
        >
          {pkg.name}
        </InsurancePackageItem>
      ))}
    </InsurancePackageSectionWrapper>
  );
};

export default CurrentInsurancePackage;
