import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { YearContainer, YearItem } from "./styles"; // Import the styled components
import './style.scss';

interface YearSelectorProps {
  years: number[];
  onYearSelect: (year: number) => void;
  otherYears: number[];
  selectedYear:number
}

const YearSelector: React.FC<YearSelectorProps> = ({ years, onYearSelect ,  otherYears  ,selectedYear }) => {
  const currentYear = new Date().getFullYear();
  const [activeYear, setActiveYear] = useState<number>(selectedYear ?? currentYear);
  const handleYearClick = (year: number) => {
    setActiveYear(year);
    onYearSelect(year);
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = parseInt(event.target.value, 10);
    if(selectedYear){
      setActiveYear(selectedYear);
      onYearSelect(selectedYear);
    }
  };

  return (
    <div>
  <YearContainer className="justify-content-center">
      {years.map((year) => (
        <YearItem
          key={year}
          isactive={year === activeYear}
          onClick={() => handleYearClick(year)}
        >
          {year}
        </YearItem>
      ))}
       <Form.Select
              aria-label="Default select example"
              className="mt-1 w-90"
              onChange={handleSelectChange}
              value={activeYear ?? ''}
            >
              <option value="">
                Other Years
              </option>
              {otherYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
    </YearContainer>
    </div>
  );
};

export default YearSelector;
