// In vehicle-trim.tsx or vehicle-trim.js
import React from "react";
import { VehicleTrimItem, VehicleTrimSectionWrapper } from "./styles";

interface Trim {
  curatedTrim: string;
  model: string;
  text: string;
  value: string;
  vehicleTypeCode: string;
}

interface VehicleTrimSectionProps {
  trims: Trim[];
  onTrimSelect: (trim: string) => void;
  selectedTrim:string;
}

const VehicleTrimSection: React.FC<VehicleTrimSectionProps> = ({
  trims,
  onTrimSelect,
  selectedTrim,
}) => {
  return (
    <VehicleTrimSectionWrapper>
      {trims.map((trim) => (
        <VehicleTrimItem
          key={trim.value}
          isactive ={trim.value===selectedTrim}
          onClick={() => onTrimSelect(trim.value)}
        >
          {trim.curatedTrim}
        </VehicleTrimItem>
      ))}
    </VehicleTrimSectionWrapper>
  );
};

export default VehicleTrimSection;
