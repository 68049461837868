import React from "react";
import { EmploymentSectionWrapper, EmploymentItem } from "./styles";

interface EmploymentSectionProps {
  items: string[];
  selectedItem: string | null;
  onItemSelect: (item: string | null) => void;
}

const EmploymentSection: React.FC<EmploymentSectionProps> = ({
  items,
  selectedItem,
  onItemSelect,
}) => {
  return (
    <EmploymentSectionWrapper>
      {items.map((item, index) => (
        <EmploymentItem
          key={index}
          selected={selectedItem === item}
          onClick={() => onItemSelect(item)}
        >
          {item}
        </EmploymentItem>
      ))}
    </EmploymentSectionWrapper>
  );
};

export default EmploymentSection;
