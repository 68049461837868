import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button, Container } from "react-bootstrap";
import Survey from "./../survey";
import './style.scss';

import {
  Heading,
  ListItem,
  Paragraph,
  RedButton,
  StyledContainer,
  Subheading,
} from "./styles";
import { useNavigate } from "react-router-dom";
import Hero1 from "./../../../assets/images/img-4.webp";
import Hero2 from "./../../../assets/images/img-7.webp";
import Hero3 from "./../../../assets/images/21-svg.webp";
import Hero4 from "./../../../assets/images/flex-insurance-2048x1365.webp";

const Home = () => {
  const navigate = useNavigate();

  // Assume userId is 10 for this example
  const userId = "10";

  const [isSurveyVisible, setIsSurveyVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  const handleLearnMoreClick = () => {
    setIsSurveyVisible(true);
  };

  const setCookie = (name: string, value: string, expires: Date, path: string = "/") => {
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=${path}`;
    console.log(`Cookie set: ${name}=${value}`);
  };

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  };

  const calculateRemainingTime = (endTime: number) => {
    const currentTime = Date.now();
    const remainingTime = Math.max(endTime - currentTime, 0); // Prevent negative values
    return Math.floor(remainingTime / 1000); // Convert to seconds
  };

  useEffect(() => {
    const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    
    let endTime = getCookie("endTime"); // Check if the end time is already set in cookies
  
    if (!endTime) {
      const currentTime = Date.now();
      
      // 1-hour timer
      const initialEndTime = currentTime + oneHour; // Timer ends 1 hour from now
      
      // 1-day expiration for cookies
      const expiresTime = new Date(currentTime + oneDay); // Cookie expiration time
      
      // Set endTime for 1 hour and userId in cookies for 1 day
      setTimeLeft(calculateRemainingTime(initialEndTime));
      setCookie("endTime", initialEndTime.toString(), expiresTime);
      setCookie("userId", userId, expiresTime); // Save userId in cookies
    } else {
      const endTimeMs = parseInt(endTime);
      const remainingTime = calculateRemainingTime(endTimeMs);
  
      if (remainingTime > 0) {
        setTimeLeft(remainingTime); // Set the remaining time from the cookie
      } else {
        // Reset the timer for another 1 hour if expired
        const currentTime = Date.now();
        const newEndTime = currentTime + oneHour;
        const expiresTime = new Date(currentTime + oneDay); // Cookie expiration time remains 1 day
        
        // Update both endTime and userId cookies
        setTimeLeft(calculateRemainingTime(newEndTime));
        setCookie("endTime", newEndTime.toString(), expiresTime);
        setCookie("userId", userId, expiresTime); // Update userId cookie
      }
    }
  }, [userId]);
  
  

  useEffect(() => {
    if (timeLeft === null || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime !== null ? prevTime - 1 : 0);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number | null) => {
    if (seconds === null) return "00:00:00";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <>

      <StyledContainer fluid className="mt-5 p-0 styled_cont">
        <Container fluid className="my_newcontainer p-0">
          <Row>
            <Col className="left_column_btn p-5 text-center">
              <h1>Limited-Time Offer: Save Up to 50% on Your Auto Insurance</h1>
              <Paragraph className="sub_head">Free quotes, secure form, competitive <strong>offers.</strong></Paragraph>
              <Image src={Hero3} fluid className="hero_myimages" />
              <Paragraph className="timer_head">Quick! Offer Ends Soon!<span className="countdown">{formatTime(timeLeft)}</span></Paragraph>
              <Button
                className="custom_btn"
                onClick={() => {
                  handleLearnMoreClick();
                  document.getElementById('getQuoteButton')?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Get Your Free Ai Quote Now &raquo;
              </Button>

            </Col>
            <Col className="right_column_btn p-0">
              <Image src={Hero4} fluid className="hero_myimages1" />
            </Col>
          </Row>
        </Container>
      </StyledContainer>

      <StyledContainer fluid className="mt-5 two_parasec">
        <Row className="mb-5">
          <Col>
            <Paragraph className="mypara">August 2024 - County: Feeling overwhelmed by soaring auto insurance premiums? You're not alone. As rates continue to skyrocket, millions of drivers are struggling to keep up. But there's a groundbreaking solution that could help you save thousands on your auto insurance—introducing ,<a href="#getQuoteButton">QuickAutoQuotes.</a></Paragraph>

            <Paragraph className="mypara">A couple of years ago, I was in the same boat—struggling with unmanageable auto insurance premiums. Despite my best efforts to find a better deal, it seemed impossible. One night, while browsing online, I stumbled upon a revolutionary tool called <a href="#getQuoteButton">QuickAutoQuotes</a>. Skeptical but desperate, I decided to give it a shot.</Paragraph>

            <Paragraph className="mypara"><a href="#getQuoteButton">QuickAutoQuotes</a> is a state-of-the-art tool designed to instantly find the best insurance rates for you. Using advanced AI algorithms, <a href="#getQuoteButton">QuickAutoQuotes</a> compares quotes from over 200 top providers, ensuring you get the most competitive rates without sacrificing coverage.</Paragraph>
          </Col>
          <Col xs lg="4" className="side_images">
            <Image src={Hero1} fluid className="myimages" />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <Paragraph className="mypara">After using <a href="#getQuoteButton">QuickAutoQuotes</a>, I was shocked by the results. My annual premium plummeted from $1,800 to just $900—a 50% reduction! With the money saved, I finally took that family vacation we had been dreaming of. Imagine what you could do with an extra $1,000 a year.</Paragraph>

            <Paragraph className="mypara">Insurance companies don't want you to know about this tool. They thrive on high premiums and hidden fees. But with <a href="#getQuoteButton">QuickAutoQuotes</a>, you can take control. This exclusive offer is limited, as insurance companies are pushing back, and our ability to provide these savings may end soon.</Paragraph>

            <Paragraph className="mypara">Curious to see how much you could save? Don't wait! Fill out our quick survey to find out if you qualify for these unprecedented savings. It's time to take charge of your financial future and enjoy the peace of mind that comes with affordable auto insurance.</Paragraph>
          </Col>
          <Col xs lg="4" className="side_images">
            <Image src={Hero2} fluid className="myimages" />
          </Col>
        </Row>
      </StyledContainer>


      <StyledContainer fluid className="mt-2">
        <Row className="justify-content-center">
          <Col md={12}>
            {/* <Row className="mt-3">
              <Col md={10}>
                <div className="text-center mt-0 mb-4">
                  <Image src={Hero1} fluid className="img-fluid" />
                </div>{" "}
              </Col>
            </Row>
            <Row>
              <Col className="text-md-left">
                <Paragraph>
                  August 2024 - County: Feeling overwhelmed by soaring auto
                  insurance premiums? You're not alone. As rates continue to
                  skyrocket, millions of drivers are struggling to keep up. But
                  there's a groundbreaking solution that could help you save
                  thousands on your auto insurance—introducing{" "}
                  <a href="#getQuoteButton" className="text-primary underline-none">QuickAutoQuotes</a>.
                </Paragraph>
                <Paragraph>
                  A couple of years ago, I was in the same boat—struggling with
                  unmanageable auto insurance premiums. Despite my best efforts
                  to find a better deal, it seemed impossible. One night, while
                  browsing online, I stumbled upon a revolutionary tool called
                  <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a>. Skeptical
                  but desperate, I decided to give it a shot.
                </Paragraph>
              </Col>
            </Row> */}
            <Row className="mb-4">
              {/* <Paragraph className="mb-1">
              <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a> is a
                state-of-the-art tool designed to instantly find the best
                insurance rates for you. Using advanced AI algorithms,{" "}
                <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a> compares
                quotes from over 200 top providers, ensuring you get the most
                competitive rates without sacrificing coverage.
              </Paragraph>
              <Row>
                <Col md={10}>
                  <div className="text-center mt-0 mb-4">
                    <Image src={Hero2} fluid className="img-fluid" />
                  </div>
                </Col>
              </Row>
              <Paragraph className="mb-1">
                After using   <a href="#getQuoteButton" className="text-primary underline-none">QuickAutoQuotes</a>, I
                was shocked by the results. My annual premium plummeted from
                $1,800 to just $900—a 50% reduction! With the money saved, I
                finally took that family vacation we had been dreaming of.&nbsp;
                <span className="fw-bold">
                  Imagine what you could do with an extra $1,000 a year.
                </span>
              </Paragraph>
              <Row>
                <Col md={10}>
                  <div className="text-center mt-0 mb-4">
                    <Image src={Hero3} fluid className="img-fluid" />
                  </div>
                </Col>
              </Row>
              <Paragraph>
                John D. from (<span className="text-danger">{city || 'Loading...'}</span>) :
                "Thanks to <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a>, I
                saved $300 on my annual premium. This tool is a lifesaver!"
              </Paragraph>
              <Paragraph>
                Sarah L. from (<span className="text-danger">{city || 'Loading...'}</span>) :
                "With
                <a href="#getQuoteButton" className="text-primary underline-none"> QuickAutoQuotes</a>, I saved
                enough to renovate my kitchen. It's unbelievable how much you
                can save!"
              </Paragraph>
              <Paragraph>
                Insurance companies don’t want you to know about this tool. They
                thrive on high premiums and hidden fees. But with   <a href="#getQuoteButton" className="text-primary underline-none">QuickAutoQuotes</a>,
                you can take control. This exclusive offer is limited, as
                insurance companies are pushing back, and our ability to provide
                these savings may end soon.
              </Paragraph>
              <Paragraph>
                Curious to see how much you could save? Don’t wait! Fill out our
                quick survey to find out if you qualify for these unprecedented
                savings. It’s time to take charge of your financial future and
                enjoy the peace of mind that comes with affordable auto
                insurance.
              </Paragraph>     */}
              <Row id="getQuoteButton" className="my_multi_form">
                {" "}
                <Col>
                  <Paragraph className="timer_head text-center">Quick! Offer Ends Soon!<span className="countdown">{formatTime(timeLeft)}</span></Paragraph>
                  {!isSurveyVisible ? (
                    <div className="text-center">
                      <RedButton onClick={handleLearnMoreClick}>
                        Get Your Free Ai Quote Now &raquo;
                      </RedButton>
                    </div>
                  ) : (
                    <Survey />
                  )}
                </Col>
              </Row>
              <Paragraph className="text-center text-danger lowser_btn">
                Limited-Time Offer: Save Up to 50% on Your Auto Insurance
              </Paragraph>
            </Row>

          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default Home;