// src/contexts/UserContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';

// Define context type
interface UserContextType {
  ip: string;
  city: string;
  setIP: (ip: string) => void;
  setCity: (city: string) => void;
}

// Default context value
const defaultContextValue: UserContextType = {
  ip: '',
  city: '',
  setIP: () => {},
  setCity: () => {},
};

// Create Context
const UserContext = createContext<UserContextType>(defaultContextValue);

// Provider Component
interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [ip, setIP] = useState<string>('');
  const [city, setCity] = useState<string>('');

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;
        setIP(ipAddress);

        const cityResponse = await fetch(`https://freeipapi.com/api/json/${ipAddress}`);
        const cityData = await cityResponse.json();
        setCity(cityData.cityName);
      } catch (error) {
        console.error('Error fetching user IP or city:', error);
      }
    };

    fetchIP();
  }, []);

  return (
    <UserContext.Provider value={{ ip, city, setIP, setCity }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
