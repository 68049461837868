export const Theme = {
  colors: {
    white: "#fff",
    grey_100: "#f4f4f4",
    grey_200: "ddd",
    grey_300: "#d8d8d8",
    balck_1000: "#2a2a2a",
    balck_900: "#666666",
    black_800: "#333333",
    black_700: "#999999",
    balck_300: "#1b1c1e",
    green_1000: "#08c177",
    green_900: "#07b26e",
    blue_1000: "#0e75bc",
    blue_300: "#00adef",
    blue_200: "rgba(14,117,188,.075)",
  },

  media: {
    md: 768,
    sm: 500,
    lg: 968,
    xl: 1440,
  },
  utils: {},
};
