import React, { useState } from "react";
import { HomeOption, HomeOwnerWrapper } from "./styles"; // Adjust the import path as necessary

interface HomeOwnerProps {
  options: string[];
  onSelect: (option: string) => void;
  selectedOption:string;
}

const HomeOwner: React.FC<HomeOwnerProps> = ({ options, onSelect , selectedOption}) => {
  const [homeOwnershipAnswer, setHomeOwnershipAnswer] = useState<string | null>(
    selectedOption
  );

  const handleOptionClick = (option: string) => {
    setHomeOwnershipAnswer(option);
    onSelect(option);
  };

  return (
    <HomeOwnerWrapper>
      {options.map((option) => (
        <HomeOption
          key={option}
          selected={homeOwnershipAnswer === option}
          onClick={() => handleOptionClick(option)}
          className="text-center"
        >
          {option}
        </HomeOption>
      ))}
    </HomeOwnerWrapper>
  );
};

export default HomeOwner;
