import React, { useState } from "react";
import {
  BooleanOption,
  BooleanQuestionWrapper,
} from "../../../../../../src/globals/global"; // Keep styled component imports the same

interface MarriedProps {
  // Renamed the interface to MarriedProps
  answers: string[];
  onSelect: (answer: string) => void;
  selectedAns:string;
}

const Married: React.FC<MarriedProps> = ({
  // Renamed the component to Married
  answers,
  onSelect,
  selectedAns,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(selectedAns);

  const handleOptionClick = (answer: string) => {
    setSelectedAnswer(answer);
    onSelect(answer);
  };

  return (
    <BooleanQuestionWrapper>
      {answers.map((answer) => (
        <BooleanOption
          key={answer}
          selected={selectedAnswer === answer}
          onClick={() => handleOptionClick(answer)}
          className={selectedAnswer === answer ? 'selected':''}
        >
          {answer}
        </BooleanOption>
      ))}
    </BooleanQuestionWrapper>
  );
};

export default Married; // Updated the export to Married
