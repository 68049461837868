import React from "react";
import {
  BooleanOption,
  BooleanQuestionWrapper,
} from "../../../../../../src/globals/global";

interface AccidentsHistoryProps {
  options: string[];
  selectedOption: string | null;
  onSelect: (option: string) => void;
}

const AccidentsHistory: React.FC<AccidentsHistoryProps> = ({
  options,
  selectedOption,
  onSelect,
}) => {
  const handleAccidentHistoryClick = (option: string) => {
    onSelect(option);
  };

  return (
    <BooleanQuestionWrapper>
      {options.map((option) => (
        <BooleanOption
          key={option}
          selected={selectedOption === option}
          className={selectedOption === option ? "selected":''}
          onClick={() => handleAccidentHistoryClick(option)}
        >
          {option}
        </BooleanOption>
      ))}
    </BooleanQuestionWrapper>
  );
};

export default AccidentsHistory;
