import styled from "styled-components";
export const SurwayWrapper = styled.div`
  .selected-question-indicator {
    font-size: 16px;
    color: #333;
    margin-bottom: 16px;
  }

  .selected-answer-paragraph {
    font-family: "Poppins Bold";
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.black_700};
    margin-bottom: 0rem;
  }
`;

const StyledInput = styled.input`
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid ${(props) => props.theme.colors.grey_300};
  color: ${(props) => props.theme.colors.balck_1000} !important;
  min-height: 72px;
  min-width: 300px;
  padding: 12px 24px;
  font-size: 30px;
  box-sizing: border-box;
  &:focus {
    border-color: ${(props) => props.theme.colors.green_1000};
    background: ${(props) => props.theme.colors.white} !important;
    outline: none; /* Optional: Removes default browser outline */
  }
  &::placeholder {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins Bold", sans-serif;
    color: #2a2a2a !important;
  }s
`;

export default StyledInput;

export const ModalWrapper = styled.div`
  .modal-heading {
    color: #333333;
    font-family: "Poppins Semibold";
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  .modal-content {
    color: #333333;
    font-family: "Poppins Regular";
    font-size: 18px;
    margin-bottom: 5px;
    border: none;
  }
  .button-modal {
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    border: none;
  }
  .back-button {
    background: #0e75bc;
    color: white;
  }
  .exit-button {
    background: #08c177;
    color: white;
  }
`;
