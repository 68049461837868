import styled from "styled-components";

export const HeroButtonWrapper = styled.div`
  .primary {
    font-family: "Poppins Bold";
    color: ${(props) => props.theme.colors.balck_1000};
    background: ${(props) => props.theme.colors.white};
    padding: 22px 40px;
    font-size: 16px;
    font-weight: 700;
    border: 2px solid ${(props) => props.theme.colors.blue_300};
    border-radius: 3px;
    height: fit-content;
    transition: all 0.5s;
    @media (max-width: 768px) {
      padding: 16px 30px;
      margin: auto;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.blue_300};
      color: ${(props) => props.theme.colors.white} !important;
    }
  }

  .secondary {
    width: 100%;
    font-family: "Poppins Bold";
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.green_900};
    padding: 0.45rem 1.8rem;
    font-size: 0.9rem;
    font-weight: 700;
    border: none;
    display: flex;
    align-items: center;
    grid-column-gap: 0.5rem;
    border-radius: 50px;
    height: fit-content;
    transition: all 0.5s;
    display: flex;
    margin: auto;
    @media (max-width: 768px) {
      margin: auto;
    }
    &:hover {
      box-shadow: 0px 4px 8px 0px #00000026;
      background-color: ${(props) => props.theme.colors.purple_400};
    }
  }
  .tertiary {
    font-family: "Poppins Bold";
    border: none;
    color: ${(props) => props.theme.colors.black_700};
    background-color: transparent;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 0px;
    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.colors.black_700};
    }
  }
  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-icon {
    display: flex;
    align-items: center;
    margin: 0 8px;
  }

  .button-text {
    display: flex;
    align-items: center;
  }
`;
