import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./views/pages/home";
import Survey from "./views/pages/survey"; // Import your Survey page
import ThankYouPage from "./views/components/pages/main/thankyou";
import ScrollToTop  from "./scroll/scroll";

const App: React.FC = () => {
  return (
    <div className="container">
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
      </Routes>
    </div>
  );
};

export default App;
