import React from "react";
import { HeadingWrapper } from "./styles";
import HeadingProps from "./utils/interfaces";

const Heading = ({ level, className, children }: any) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <HeadingWrapper>
      <HeadingTag className={`${className}`}>{children}</HeadingTag>
    </HeadingWrapper>
  );
};

export default Heading;
