import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.css'; // Ensure to create this CSS file for custom styles

const Loader: React.FC = () => {
  return (
    <div className="loader-container d-flex flex-column align-items-center justify-content-center">
      <Spinner animation="border" role="status" variant="primary" />
      <div className="loading-text mt-3">Loading, please wait...</div>
    </div>
  );
};

export default Loader;
