import React from "react";
import { Form } from "react-bootstrap";
import {
  InsuranceProviderItem,
  InsuranceProvidersSectionWrapper,
} from "./styes";

interface InsuranceProvider {
  name: string;
  value: string;
}

const insuranceCompanies: InsuranceProvider[] = [
  { name: "AAA", value: "aaa" },
  { name: "Amica", value: "amica" },
  { name: "Chubb", value: "chubb" },
  { name: "Country Financial", value: "country-financial" },
  { name: "Dairyland", value: "dairyland" },
  { name: "Erie Insurance", value: "erie-insurance" },
  { name: "Hartford", value: "hartford" },
  { name: "Kemper", value: "kemper" },
  { name: "Mapfre", value: "mapfre" },
  { name: "Mercury", value: "mercury" },
  { name: "Shelter", value: "shelter" },
  { name: "Other", value: "other" },
];

interface CurrentInsuranceProps {
  insuranceProviders: InsuranceProvider[];
  onInsuranceCompanySelect: (provider: string) => void;
  currentInsurance: string;
}

const CurrentInsurance: React.FC<CurrentInsuranceProps> = ({
  insuranceProviders,
  onInsuranceCompanySelect,
  currentInsurance,
}) => {
  // Handle the change event for the select input
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onInsuranceCompanySelect(selectedValue);
  };

  return (
    <InsuranceProvidersSectionWrapper>
      {insuranceProviders.map((provider) => (
        <InsuranceProviderItem
          className={currentInsurance === provider.value ? "selected" : ""}
          key={provider.value}
          onClick={() => onInsuranceCompanySelect(provider.value)}
        >
          {provider.name}
        </InsuranceProviderItem>
      ))}

      {insuranceCompanies.length > 0 && (
        <Form.Select
          aria-label="Default select example"
          className="w-100"
          onChange={handleSelectChange}
        >
          <option value="">Select a Provider</option>
          {insuranceCompanies.map((company) => (
            <option key={company.value} value={company.value}>
              {company.name}
            </option>
          ))}
        </Form.Select>
      )}
    </InsuranceProvidersSectionWrapper>
  );
};

export default CurrentInsurance;
