import React, { useState } from "react";
import CustomButton from "../../../../components/shared/Button";
import styled from "styled-components";

// Define styled components
const StyledBooleanOption = styled.div<{ selected: boolean }>`
  background: ${(props) => props.theme.colors.blue_200};
  color: ${(props) => props.theme.colors.blue_1000};
  border: 2px solid ${(props) => props.theme.colors.blue_200};
  border-radius: 4px;
  padding: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%;
  &:hover {
    border-color: ${(props) => props.theme.colors.blue_1000};
  }
  &:active {
    border-color: ${(props) => props.theme.colors.blue_1000};
  }

  &:active {
    background: ${(props) => props.theme.colors.blue_1000};
    color: ${(props) => props.theme.colors.white};
  }
`;

const StyledBooleanQuestionWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray_100};
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 1rem;
`;

const OptionsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.gray_100};
  border-radius: 8px;
`;

interface LegalQuestionsProps {
  selectedQuestions: Record<string, string | null>;
  onQuestionSelect: (question: string, response: string) => void;
  onSubmitQuestions: (answers: Record<string, string | null>) => void;
}
const LegalQuestions: React.FC<LegalQuestionsProps> = ({
  onSubmitQuestions,
  selectedQuestions,
  onQuestionSelect,
}) => {
  const questions = Object.keys(selectedQuestions);

  // Check if all questions have been answered
  const allQuestionsAnswered = questions.every(
    (question) => selectedQuestions[question] !== null
  );

  
  const handleSubmit = () => {
    if (allQuestionsAnswered) {
      onSubmitQuestions(selectedQuestions);
    }
  };

  return (
    <div>
      {questions.map((question) => (
        <StyledBooleanQuestionWrapper key={question}>
          <h3>{question}</h3>
          <OptionsContainer>
            <StyledBooleanOption
              className={selectedQuestions[question] === "Yes" ?'selected':''}
              selected={selectedQuestions[question] === "Yes"}
              onClick={() => onQuestionSelect(question, "Yes")}
            >
              Yes
            </StyledBooleanOption>
            <StyledBooleanOption
              className={selectedQuestions[question] === "No" ?'selected':''}
              selected={selectedQuestions[question] === "No"}
              onClick={() => onQuestionSelect(question, "No")}
            >
              No
            </StyledBooleanOption>
          </OptionsContainer>
        </StyledBooleanQuestionWrapper>
      ))}
      <CustomButton
        className="primary w-100"
        onClick={handleSubmit}
        disabled={!allQuestionsAnswered} // Disable button if not all questions are answered
      >
        Submit
      </CustomButton>
    </div>
  );
};

export default LegalQuestions;
