import React from "react";
import styled from "styled-components";
import CustomButton from "../../../shared/Button";
import Heading from "../../../shared/Heading"; // Adjust the import path
import { Alert, Col, Row } from "react-bootstrap";

// Styled wrapper for the PersonalInfo component
const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Styled input for text fields
const StyledInput = styled.input`
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid ${(props) => props.theme.colors.grey_300};
  color: ${(props) => props.theme.colors.black_1000} !important;
  min-height: 72px;
  min-width: 300px;
  padding: 12px 24px;
  font-size: 30px;
  box-sizing: border-box;

  &:focus {
    border-color: ${(props) => props.theme.colors.green_1000};
    background: ${(props) => props.theme.colors.white} !important;
    outline: none; /* Optional: Removes default browser outline */
  }

  &::placeholder {
    font-size: 16px;
    color: ${(props) => props.theme.colors.black_1000} !important;
  }
`;

// Styled button wrapper for alignment
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
`;

interface PersonalInfoErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
}
// Props for PersonalInfo component
interface PersonalInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  onFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (firstName: string, lastName: string, email: string) => void;
  errors: PersonalInfoErrors;
}

// PersonalInfo component
const PersonalInfo: React.FC<PersonalInfoProps> = ({
  firstName,
  lastName,
  email,
  onFirstNameChange,
  onLastNameChange,
  onEmailChange,
  onSubmit,
  errors,
}) => {
  return (
    <PersonalInfoWrapper className="w-100">
      {/* Heading */}
      <Row className="justify-between  mb-3">
        <Col md={12}>
          <StyledInput
            type="text"
            value={firstName}
            className="w-100"
            onChange={onFirstNameChange}
            placeholder="First Name"
          />
          {errors.firstName && (
            <div className="error_message">{errors.firstName}</div>
          )}
        </Col>
      </Row>
      <Row className="justify-between mb-3">
        {/* Last Name Input */}
        <Col md={12}>
          <StyledInput
            type="text"
            className="w-100"
            value={lastName}
            onChange={onLastNameChange}
            placeholder="Last Name"
          />
          {errors.lastName && (
            <div className="error_message">{errors.lastName}</div>
          )}
        </Col>
      </Row>
      {/* Email Input */}
      <Row className="justify-between mb-3">
        <Col md={12}>
          <StyledInput
            type="email"
            className="w-100"
            value={email}
            onChange={onEmailChange}
            placeholder="Email Address"
          />
          {errors.email && <div className="error_message">{errors.email}</div>}
        </Col>
      </Row>
      {/* Button */}
      <ButtonWrapper>
        <CustomButton
          className="primary w-100"
          onClick={() => onSubmit(firstName, lastName, email)}
        >
          Continue
        </CustomButton>
      </ButtonWrapper>
    </PersonalInfoWrapper>
  );
};

export default PersonalInfo;
