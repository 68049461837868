import React, { useState, useEffect } from "react";

import {
  ProgressBar,
  Container,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import { questions } from "../../../utils/survay-data/Questions/data";
import { years } from "../../../utils/vehicle-years/data";
import { otherYears } from "../../../utils/vehicle-years/data";
import { insuranceProviders } from "../../../utils/insurance-company/data";
import { InsurancePackages } from "../../../utils/insurance-limit/data";
import Heading from "../../components/shared/Heading";
import CustomButton from "../../components/shared/Button";
import StyledInput, { ModalWrapper, SurwayWrapper } from "./styles";
import VehicleYearSelector from "../../components/pages/survey/vehicle-year-selection";
import VehicleMakesSection from "../../components/pages/survey/vehicle-make";
import VehicleModelsSection from "../../components/pages/survey/vehicle-model";
import VehicleTrimSection from "../../components/pages/survey/vehicle-trim";
import AutoInsurance from "../../components/pages/survey/auto-insurance";
import PreviousIcon from "../../../assets/icons/PreviousIcon";
import CloseIcon from "../../../assets/icons/CloseIcon";
import CurrentInsurance from "../../components/pages/survey/current-insurance";
import CurrentInsurancePackage from "../../components/pages/survey/current-insurence-package";
import Gender from "../../components/pages/survey/gender";
import Married from "../../components/pages/survey/married";
import OtherVehicle from "../../components/pages/survey/other-vehicle";
import HomeOwner from "../../components/pages/survey/home-ownership";
import RentersInsurance from "../../components/pages/survey/rental-insurance";
import EducationSection from "../../components/pages/survey/education";
import { educationItems } from "../../../utils/education/data";
import { employmentStatusItems } from "../../../utils/employment/data";
import EmploymentSection from "../../components/pages/survey/employment";
import DateOfBirth from "../../components/pages/survey/birth-date";
import MilitarySpouse from "../../components/pages/survey/milatry-spous";
import PersonalInfo from "../../components/pages/survey/personal-info";
import AddressInfo from "../../components/pages/survey/address";
import { Navigate, useNavigate } from "react-router-dom";
import AccidentsHistory from "../../components/pages/survey/accident-history";
import LegalQuestions from "../../components/pages/survey/legal-infraction";
import { isValid } from "react-datepicker/dist/date_utils";
import Loader from "./../../components/pages/main/loader";

interface Trim {
  curatedTrim: string;
  model: string;
  text: string;
  value: string;
  vehicleTypeCode: string;
}

interface PersonalInfoErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
}
interface AddressInfoErrors {
  street?: string;
  zip?: string;
  phone?: string;
}

const Survey: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<number>(1);
  const [progress, setProgress] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedOtherVehicleYear, setOtherVehicleYearSelectedYear] = useState<
    number | null
  >(null);
  const [selectedMake, setSelectedMake] = useState<string | null>(null);
  const [selectedOtherVehicleMake, setSelectedOtherVehicleMake] = useState<
    string | null
  >(null);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const [selectedOtherVehicleModel, setSelectedOtherVehicleModel] = useState<
    string | null
  >(null);
  const [selectedTrim, setSelectedTrim] = useState<string | null>(null);
  const [selectedOtherVehicleTrim, setSelectedOtherVehicleTrim] = useState<
    string | null
  >(null);
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [trimLevels, setTrimLevels] = useState<Trim[]>([]);

  const [makesOtherVehicle, setMakesOtherVehicle] = useState<string[]>([]);
  const [modelsOtherVehicle, setModelsOtherVehicle] = useState<string[]>([]);
  const [trimLevelsOtherVehicle, setTrimLevelsOtherVehicle] = useState<Trim[]>(
    []
  );

  const [autoInsuranceAnswers, setAutoInsuranceAnswers] = useState<
    string | null
  >(null);
  const [accidentAnswers, setaccidentAnswers] = useState<string | null>(null);
  const [currentInsuranceAnswers, setCurrentInsuranceAnswers] = useState<
    string | null
  >(null);
  const [legalAnswers, setLegalAnswers] = useState<
    Record<string, string | null>
  >({});
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);

  const [genderAnswers, setGenderAnswers] = useState<string | null>(null);
  const [marriedAnswers, setMarriedAnswers] = useState<string | null>(null);
  const [anotherVehicleAns, setanotherVehicleAns] = useState<string | null>(
    null
  );

  const [homeOwnershipAnswer, setHomeOwnershipAnswer] = useState<string | null>(
    null
  );
  const [personalInfoerrors, setPersonalInfoErrors] =
    React.useState<PersonalInfoErrors>({});
  const [addressInfo, setAddressInfo] = React.useState<AddressInfoErrors>({});

  const [rentersInsuranceAnswer, setRentersInsuranceAnswer] = useState<
    string | null
  >(null);
  const [educationAnswer, setEducationAnswer] = useState<string | null>(null);
  const [selectedEmployment, setSelectedEmployment] = useState<string | null>(
    null
  );
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [selectedSpouse, setSelectedSpouse] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [zipError, setZipError] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [selectedAccidentHistory, setSelectedAccidentHistory] = useState<
    string | null
  >(null);
  const [show, setShow] = useState(false);
  // Handle form submission for legal
  const [selectedQuestions, setSelectedQuestions] = useState<
    Record<string, string | null>
  >({
    "An at-fault accident in the past three (3) years?": null,
    "Two (2) or more tickets in the past three (3) years?": null,
    "A DUI conviction in the past three (3) years?": null,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleFormExit = () => {
    navigate("/");
  };

  useEffect(() => {
    if (selectedYear) {
      fetchMakes(selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedMake && selectedYear) {
      fetchModels(selectedMake);
    }
  }, [selectedMake]);

  useEffect(() => {
    if (selectedModel && selectedMake && selectedYear) {
      fetchTrimLevels(selectedModel);
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedOtherVehicleYear) {
      fetchMakesOtherVehicle(selectedOtherVehicleYear);
    }
  }, [selectedOtherVehicleYear]);

  useEffect(() => {
    if (selectedOtherVehicleMake && selectedOtherVehicleYear) {
      fetchModelsOtherVehicle(selectedOtherVehicleMake);
    }
  }, [selectedOtherVehicleMake]);

  useEffect(() => {
    if (
      selectedOtherVehicleModel &&
      selectedOtherVehicleMake &&
      selectedOtherVehicleYear
    ) {
      fetchTrimLevelsOtherVehicle(selectedOtherVehicleModel);
    }
  }, [selectedOtherVehicleModel]);

  const fetchMakes = async (year: number) => {
    try {
      const response = await fetch(
        `https://form.quotewizard.com/kube/nxrdpolk/curated/${year}.json`
      );
      const data = await response.json();
      setMakes(data);
    } catch (error) {
      console.error("Error fetching makes:", error);
    }
  };

  const fetchModels = async (make: string) => {
    try {
      const response = await fetch(
        `https://form.quotewizard.com/kube/nxrdpolk/curated/${selectedYear}/${make?.toUpperCase()}.json`
      );
      const data = await response.json();
      setModels(data);
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  };

  const fetchTrimLevels = async (model: string) => {
    try {
      const response = await fetch(
        `https://form.quotewizard.com/kube/nxrdpolk/curated/${selectedYear}/${selectedMake?.toUpperCase()}/${model?.toUpperCase()}.json`
      );
      const data = await response.json();
      setTrimLevels(data);
    } catch (error) {
      console.error("Error fetching trim levels:", error);
    }
  };

  const fetchMakesOtherVehicle = async (year: number) => {
    try {
      const response = await fetch(
        `https://form.quotewizard.com/kube/nxrdpolk/curated/${year}.json`
      );
      const data = await response.json();
      setMakesOtherVehicle(data);
    } catch (error) {
      console.error("Error fetching makes:", error);
    }
  };

  const fetchModelsOtherVehicle = async (make: string) => {
    try {
      const response = await fetch(
        `https://form.quotewizard.com/kube/nxrdpolk/curated/${selectedOtherVehicleYear}/${make?.toUpperCase()}.json`
      );
      const data = await response.json();
      setModelsOtherVehicle(data);
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  };

  const fetchTrimLevelsOtherVehicle = async (model: string) => {
    try {
      const response = await fetch(
        `https://form.quotewizard.com/kube/nxrdpolk/curated/${selectedOtherVehicleYear}/${selectedOtherVehicleMake?.toUpperCase()}/${model?.toUpperCase()}.json`
      );
      const data = await response.json();
      setTrimLevelsOtherVehicle(data);
    } catch (error) {
      console.error("Error fetching trim levels:", error);
    }
  };

  const handleNextQuestion = (answer?: string) => {
    const AutoInsurence = answer ?? autoInsuranceAnswers;
    const HomeOwner = answer ?? homeOwnershipAnswer;
    const AccidentHistory = answer ?? selectedAccidentHistory;
    const anotherVehicle = answer ?? anotherVehicleAns;

    let newQuestionIndex = currentQuestion + 1;

    if (currentQuestion === 19 && AccidentHistory === "No") {
      newQuestionIndex = currentQuestion + 2;
    }
    // Skip next 1 questions if the answer is "Own" in the Home Owner section
    if (currentQuestion === 17 && HomeOwner !== "I Rent") {
      newQuestionIndex = currentQuestion + 2;
    }

    // Skip next 2 questions if the answer is "yes" in the Auto Insurance section
    if (currentQuestion === 10 && AutoInsurence === "No") {
      newQuestionIndex = currentQuestion + 3;
    }
    if (currentQuestion === 5 && anotherVehicle == "No") {
      newQuestionIndex = currentQuestion + 5;
    }
    if (newQuestionIndex < questions.length) {
      setCurrentQuestion(newQuestionIndex);
      setProgress(((newQuestionIndex + 1) / questions.length) * 100);
    } else {
      const questionToKeyMapping: Record<string, string> = {
        "An at-fault accident in the past three (3) years?":
          "driving_incidents_fault",
        "Two (2) or more tickets in the past three (3) years?":
          "driving_incidents_tickets",
        "A DUI conviction in the past three (3) years?":
          "driving_incidents_dui_conviction",
      };

      // Transform the state into the new form object
      const formObject = Object.keys(selectedQuestions).reduce<
        Record<string, string | null>
      >((acc, question) => {
        const formKey = questionToKeyMapping[question];
        if (formKey) {
          acc[formKey] = selectedQuestions[question];
        }
        return acc;
      }, {});
      const formatDate = (date: Date | null | ""): string => {
        if (date && !(date instanceof Date)) {
          throw new Error("Invalid date object");
        }

        if (!date) return "";

        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      };
      const extendedFormObject = {
        ...formObject,
        zip: zip,
        vehicle_year: selectedYear,
        second_vehicle_year: selectedOtherVehicleYear,
        vehicle_model: selectedModel,
        second_vehicle_model: selectedOtherVehicleModel,
        vehicle_make: selectedMake,
        second_vehicle_make: selectedOtherVehicleMake,
        trim_level: selectedTrim,
        second_trim_level: selectedOtherVehicleTrim,
        had_auto_insurance: autoInsuranceAnswers,
        current_auto_insurance: currentInsuranceAnswers,
        duration_auto_insurance: selectedPackage,
        gender: genderAnswers,
        marital_status: marriedAnswers,
        education_level: educationAnswer,
        employment_status: selectedEmployment,
        home_owner_status: homeOwnershipAnswer,
        renter_insurance_quote: rentersInsuranceAnswer,
        driving_incidents: selectedAccidentHistory,
        military_status: selectedSpouse,
        date_of_birth: formatDate(dateOfBirth),
        firstname: firstName,
        lastname: lastName,
        email: email,
        address: street,
        phone: phone,
        has_second_vehicle: anotherVehicleAns,
      };
      setLoading(true);
      const raw = JSON.stringify({
        properties: extendedFormObject,
      });

      const requestOptions: RequestInit = {
        method: "POST",
        body: raw,
      };

      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            "https://quickautoquotes.net/hubspot.php",
            requestOptions
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          setLoading(false);
          navigate('/thank-you');
        } catch (error) {
          console.error("Error:", error);
        }
      };
      try{
        fetchData();
      }
      catch(error){

      }
    }
  };

  const handleZipSubmit = () => {
    if (zip && validateZipCode(zip)) {
      setZipError("");
      handleNextQuestion();
    } else {
      setZipError("Please Enter A Valid Zip Code");
    }
  };
  const handleAccidentHistorySelect = (option: string) => {
    setSelectedAccidentHistory((prevAnswer) => {
      const updatedAnswer = option;
      handleNextQuestion(updatedAnswer);
      return updatedAnswer;
    });
  };
  const handleDateOfBirthChange = (date: Date | null) => {
    setDateOfBirth(date);
  };
  //previous question
  const handlePreviousQuestion = () => {
    if (currentQuestion > 1) {
      if (currentQuestion === 10 && anotherVehicleAns == "No") {
        setCurrentQuestion(currentQuestion - 5);
      } else if (currentQuestion === 13 && autoInsuranceAnswers === "No") {
        // If on question 8 and the answer is "No", go back 3 questions
        setCurrentQuestion(currentQuestion - 3);
      } else if (currentQuestion === 19 && homeOwnershipAnswer !== "I Rent") {
        // If on question 10 and the answer is "Own", go back 2 questions
        setCurrentQuestion(currentQuestion - 2);
      } else if (currentQuestion === 21 && selectedAccidentHistory === "No") {
        // If on question 10 and the answer is "Own", go back 2 questions
        setCurrentQuestion(currentQuestion - 2);
      } else {
        // For other cases, go back 1 question
        setCurrentQuestion(currentQuestion - 1);
      }

      // Update progress
      setProgress(((currentQuestion - 1) / questions.length) * 100);
    }
  };
  const handleQuestionSelect = (question: string, response: string) => {
    setSelectedQuestions((prevState) => {
      const updatedState: Record<string, string | null> = {
        ...prevState,
        [question]: response,
      };
      return updatedState;
    });
  };

  const handleExit = () => {};
  const handlePersonalInfoSubmit = (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    let errors: PersonalInfoErrors = {};
    setPersonalInfoErrors(errors);
    if (!firstName.trim()) {
      errors.firstName = "First name is required.";
    }
    if (!lastName.trim()) {
      errors.lastName = "Last name is required.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is not valid.";
    }
    if (Object.keys(errors).length > 0) {
      setPersonalInfoErrors(errors);
      return;
    }
    handleNextQuestion();
  };
  const handleAddressSubmit = (street: string, zip: string, phone: string) => {
    let errors: AddressInfoErrors = {};
    setAddressInfo(errors);

    if (!street.trim()) {
      errors.street = "Street is required.";
    }

    if (!zip.trim()) {
      errors.zip = "Zip is required.";
    }
    
    if(!validateZipCode(zip)){
      errors.zip = "Enter Valid Zip Code.";
    }

    if (!phone.trim()) {
      errors.phone = "Phone is required.";
    } else if (phone.length <= 7 || phone.length > 14) {
      errors.phone = "Phone must be between 8 and 14 characters.";
    }

    if (Object.keys(errors).length > 0) {
      setAddressInfo(errors);
      return;
    }
    handleNextQuestion();
  };

  // const handleAddressSubmit = (street: string, zip: string, phone: string) => {
  //   handleNextQuestion();
  // };

  const handleYearSelect = (year: number) => {
    setSelectedYear(year);
    handleNextQuestion();
  };

  const handleOtherVehicleYearSelect = (year: number) => {
    setOtherVehicleYearSelectedYear(year);
    handleNextQuestion();
  };
  const handleMakeSelect = (make: string) => {
    setSelectedMake(make);
    handleNextQuestion();
  };

  const handleOtherVehicleMakeSelect = (make: string) => {
    setSelectedOtherVehicleMake(make);
    handleNextQuestion();
  };
  const handleSpouseSelect = (option: string) => {
    setSelectedSpouse(option);
    handleNextQuestion();
  };

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(e.target.value);
  };

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZip(e.target.value);
  };

  const validateZipCode = (code: string): boolean => {
    return /^\d{5}$/.test(code);
  };
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };
  const handleModelSelect = (model: string) => {
    setSelectedModel(model);
    handleNextQuestion();
  };

  const handleOtherVehicleModelSelect = (model: string) => {
    setSelectedOtherVehicleModel(model);
    handleNextQuestion();
  };

  const handleTrimSelect = (trim: string) => {
    setSelectedTrim(trim);
    handleNextQuestion();
  };

  const handleOtherVehicleTrimSelect = (trim: string) => {
    setSelectedOtherVehicleTrim(trim);
    handleNextQuestion();
  };

  const handleAutoInsuranceAnswerSelect = (answer: string) => {
    setAutoInsuranceAnswers((prevAnswer) => {
      const updatedAnswer = answer;
      handleNextQuestion(updatedAnswer);
      return updatedAnswer;
    });
  };

  const handleLegalQuestionsSubmit = (
    answers: Record<string, string | null>
  ) => {
    setLegalAnswers(answers);
    handleNextQuestion();
  };

  // currentinsurence
  const handleCurrentInsuranceAnswerSelect = (insuranceCompany: string) => {
    setCurrentInsuranceAnswers(insuranceCompany);
    handleNextQuestion(); // Call to handle the next question
  };

  //package select
  const handlePackageSelect = (packageName: string) => {
    setSelectedPackage(packageName);
    handleNextQuestion();
  };

  //gender select
  const handleGenderAnswerSelect = (answer: string) => {
    setGenderAnswers(answer);
    handleNextQuestion();
  };

  //first name
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  //last name
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  //email
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const hanleOtherVehicleSelect = (answer: string) => {
    setanotherVehicleAns(answer);
    handleNextQuestion(answer);
  };

  const handleMarriedAnswerSelect = (answer: string) => {
    setMarriedAnswers(answer);
    handleNextQuestion();
  };
  const handleEducationSelect = (education: string) => {
    setEducationAnswer(education);
    handleNextQuestion();
  };
  const handledateSubmit = (date: Date | null) => {
    if (date) {
      setFormSubmitted(true);
      handleNextQuestion();
    }
  };
  const handleEmploymentSelect = (employment: string | null) => {
    setSelectedEmployment(employment);
    handleNextQuestion(); // Ensure this function is defined
  };
  const handleHomeOwnershipSelect = (option: string) => {
    setHomeOwnershipAnswer((prevAnswer) => {
      const updatedAnswer = option;
      handleNextQuestion(updatedAnswer);
      return updatedAnswer;
    });
  };
  const handleRentersInsuranceAnswerSelect = (answer: string | null) => {
    setRentersInsuranceAnswer(answer);
    handleNextQuestion();
  };

  const handleContinueWithoutAnswer = () => {
    setRentersInsuranceAnswer(null);
    handleNextQuestion();
  };

  const getQuestionText = (question: string) => {
    return question
      .replace("{year}", selectedYear ? selectedYear.toString() : "")
      .replace("{make}", selectedMake ? selectedMake : "")
      .replace("{model}", selectedModel ? selectedModel : "")
      .replace("{trim}", selectedTrim ? selectedTrim : "")

      .replace(
        "{year2}",
        selectedOtherVehicleYear ? selectedOtherVehicleYear.toString() : ""
      )
      .replace(
        "{make2}",
        selectedOtherVehicleMake ? selectedOtherVehicleMake : ""
      )
      .replace(
        "{model2}",
        selectedOtherVehicleModel ? selectedOtherVehicleModel : ""
      )
      .replace(
        "{trim2}",
        selectedOtherVehicleTrim ? selectedOtherVehicleTrim : ""
      );
  };
  return (
    <SurwayWrapper>
      {loading ? (
        <Loader />
      ) : (
        <Container className="my-5 questions-container">
          <Row>
            <Col>
              <div
                className="progress-container"
                style={{ position: "relative" }}
              >
                <ProgressBar
                  now={progress}
                  className="mb-5 custom-progress-bar"
                  style={{ height: "16px", borderRadius: "20px" }}
                />
                <div
                  className="progress-percentage"
                  style={{
                    position: "absolute",
                    bottom: "-32px",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Poppins Semibold",
                    color: "#08c177",
                  }}
                >
                  {`${Math.round(progress)}% Complete`}
                </div>
              </div>
            </Col>
          </Row>
          {currentQuestion > 1 && (
            <Row className="mb-4">
              <Col className="px-0">
                <CustomButton
                  className="tertiary"
                  onClick={handlePreviousQuestion}
                  icon={<PreviousIcon />}
                  iconPosition="before"
                >
                  Previous Question
                </CustomButton>
              </Col>
              <Col className="text-end px-0">
                <CustomButton
                  className="tertiary"
                  // onClick={handleExit}
                  icon={<CloseIcon />}
                  iconPosition="after"
                  onClick={handleShow}
                >
                  Exit
                </CustomButton>
              </Col>
            </Row>
          )}
          <Row className="justify-content-center">
            <div className="question-card">
              {currentQuestion > 1 && (
                <p className={`selected-answer-paragraph ${currentQuestion}`}>
                  {currentQuestion === 2 && selectedYear && selectedYear}
                  {currentQuestion === 3 &&
                    selectedYear &&
                    selectedMake &&
                    `${selectedYear} ${selectedMake}`}
                  {currentQuestion === 4 &&
                    selectedYear &&
                    selectedMake &&
                    selectedModel &&
                    `${selectedYear} ${selectedMake} ${selectedModel}`}
                </p>
              )}
              <Heading level={2} className={currentQuestion === 1 ? "" : ""}>
                {getQuestionText(questions[currentQuestion]?.question || "")}
              </Heading>
              <div className="d-flex gap-4 mt-4 flex-wrap">
                {currentQuestion === 0 ? (
                  <>
                    <StyledInput
                      type="number"
                      value={zip}
                      placeholder=""
                      onChange={handleZipChange}
                      className="w-100"
                    />
                    <CustomButton
                      className="primary w-100"
                      onClick={handleZipSubmit}
                    >
                      Check Rates
                    </CustomButton>
                    {zipError && (
                      <div className="error_message">
                        Please enter a valid zip code
                      </div>
                    )}
                  </>
                ) : currentQuestion === 1 ? (
                  <>
                    <VehicleYearSelector
                      years={years}
                      otherYears={otherYears}
                      selectedYear={selectedYear ?? 0}
                      onYearSelect={handleYearSelect}
                    />
                  </>
                ) : currentQuestion === 2 ? (
                  <>
                    <VehicleMakesSection
                      selectedMake={selectedMake ?? ""}
                      makes={makes}
                      onMakeSelect={handleMakeSelect}
                      selectedYear={selectedYear}
                    />
                  </>
                ) : currentQuestion === 3 ? (
                  <>
                    <VehicleModelsSection
                      models={models}
                      onModelSelect={handleModelSelect}
                      selectedModel={selectedModel ?? ""}
                    />
                  </>
                ) : currentQuestion === 4 ? (
                  <>
                    <VehicleTrimSection
                      trims={trimLevels}
                      selectedTrim={selectedTrim ?? ""}
                      onTrimSelect={handleTrimSelect}
                    />
                  </>
                ) : currentQuestion === 5 ? (
                  <>
                    <OtherVehicle
                      answers={["Yes", "No"]}
                      onSelect={hanleOtherVehicleSelect}
                      selectedAns={anotherVehicleAns ?? ""}
                    />
                  </>
                ) : currentQuestion === 6 ? (
                  <>
                    <VehicleYearSelector
                      years={years}
                      otherYears={otherYears}
                      selectedYear={selectedOtherVehicleYear ?? 0}
                      onYearSelect={handleOtherVehicleYearSelect}
                    />
                  </>
                ) : currentQuestion === 7 ? (
                  <>
                    <VehicleMakesSection
                      selectedMake={selectedOtherVehicleMake ?? ""}
                      makes={makesOtherVehicle}
                      onMakeSelect={handleOtherVehicleMakeSelect}
                      selectedYear={selectedOtherVehicleYear}
                    />
                  </>
                ) : currentQuestion === 8 ? (
                  <>
                    <VehicleModelsSection
                      models={modelsOtherVehicle}
                      onModelSelect={handleOtherVehicleModelSelect}
                      selectedModel={selectedOtherVehicleModel ?? ""}
                    />
                  </>
                ) : currentQuestion === 9 ? (
                  <>
                    <VehicleTrimSection
                      trims={trimLevelsOtherVehicle}
                      selectedTrim={selectedOtherVehicleTrim ?? ""}
                      onTrimSelect={handleOtherVehicleTrimSelect}
                    />
                  </>
                ) : currentQuestion === 10 ? (
                  <>
                    <AutoInsurance
                      answers={["Yes", "No"]}
                      seletedAns={autoInsuranceAnswers ?? ""}
                      onSelect={handleAutoInsuranceAnswerSelect}
                    />
                  </>
                ) : currentQuestion === 11 ? (
                  <>
                    <CurrentInsurance
                      insuranceProviders={insuranceProviders}
                      currentInsurance={currentInsuranceAnswers ?? ""}
                      onInsuranceCompanySelect={
                        handleCurrentInsuranceAnswerSelect
                      }
                    />
                  </>
                ) : currentQuestion === 12 ? (
                  <>
                    <CurrentInsurancePackage
                      packages={InsurancePackages}
                      onPackageSelect={handlePackageSelect}
                      selectedPacakge={selectedPackage ?? ""}
                    />
                  </>
                ) : currentQuestion === 13 ? (
                  <>
                    <Gender
                      answers={["Male", "Female"]}
                      selectedGender={genderAnswers ?? ""}
                      onSelect={handleGenderAnswerSelect}
                    />
                  </>
                ) : currentQuestion === 14 ? (
                  <>
                    <Married
                      answers={["Yes", "No"]}
                      onSelect={handleMarriedAnswerSelect}
                      selectedAns={marriedAnswers ?? ""}
                    />{" "}
                  </>
                ) : currentQuestion === 15 ? (
                  <>
                    <EducationSection
                      items={educationItems}
                      selectedEducation={educationAnswer ?? ""}
                      onItemSelect={handleEducationSelect}
                    />
                  </>
                ) : currentQuestion === 16 ? (
                  <>
                    <EmploymentSection
                      items={employmentStatusItems}
                      selectedItem={selectedEmployment}
                      onItemSelect={handleEmploymentSelect}
                    />
                  </>
                ) : currentQuestion === 17 ? (
                  <>
                    <HomeOwner
                      options={[
                        "I own my home",
                        "I own my condo",
                        "I Rent",
                        "I own / rent my manufactured / mobile home",
                      ]}
                      onSelect={handleHomeOwnershipSelect}
                      selectedOption={homeOwnershipAnswer ?? ""}
                    />
                  </>
                ) : currentQuestion === 18 ? (
                  <>
                    <RentersInsurance
                      answers={["Yes", "No"]}
                      selectedAnswer={rentersInsuranceAnswer}
                      onAnswerSelect={handleRentersInsuranceAnswerSelect}
                      onContinueWithoutAnswer={handleContinueWithoutAnswer} // Pass the handler
                    />
                  </>
                ) : currentQuestion === 19 ? (
                  <>
                    <AccidentsHistory
                      options={["Yes", "No"]}
                      selectedOption={selectedAccidentHistory}
                      onSelect={handleAccidentHistorySelect}
                    />
                  </>
                ) : currentQuestion === 20 &&
                  selectedAccidentHistory == "Yes" ? (
                  <>
                    {" "}
                    <LegalQuestions
                      selectedQuestions={selectedQuestions}
                      onSubmitQuestions={handleLegalQuestionsSubmit}
                      onQuestionSelect={handleQuestionSelect}
                    />
                  </>
                ) : currentQuestion === 21 ? (
                  <>
                    {" "}
                    <MilitarySpouse
                      onSelect={handleSpouseSelect}
                      selectedSpouse={selectedSpouse ?? ""}
                    />
                  </>
                ) : currentQuestion === 22 ? (
                  <>
                    <DateOfBirth
                      selectedDate={dateOfBirth}
                      onDateChange={handleDateOfBirthChange}
                      onSubmit={handledateSubmit}
                    />
                  </>
                ) : currentQuestion === 23 ? (
                  <>
                    <PersonalInfo
                      firstName={firstName}
                      errors={personalInfoerrors}
                      lastName={lastName}
                      email={email}
                      onFirstNameChange={handleFirstNameChange}
                      onLastNameChange={handleLastNameChange}
                      onEmailChange={handleEmailChange}
                      onSubmit={handlePersonalInfoSubmit}
                    />
                  </>
                ) : currentQuestion === 24 ? (
                  <>
                    <AddressInfo
                      street={street}
                      zip={zip}
                      phone={phone}
                      firstName={firstName}
                      errors={addressInfo}
                      onStreetChange={handleStreetChange}
                      onZipChange={handleZipChange}
                      onPhoneChange={handlePhoneChange}
                      onSubmit={handleAddressSubmit}
                    />
                  </>
                ) : (
                  <div className="no-content">
                    <p>No content available for this question.</p>
                  </div>
                )}
              </div>
            </div>
          </Row>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <ModalWrapper>
              <Modal.Header closeButton>
                <Modal.Title className="modal-heading">
                  Are you sure?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-content">
                You have a few more questions to get your rates
              </Modal.Body>
              <div className="d-flex justify-content-between p-3">
                <Button
                  onClick={handleClose}
                  className="button-modal back-button"
                >
                  Back To Form
                </Button>
                <Button
                  onClick={handleFormExit}
                  className="button-modal exit-button"
                >
                  Exit
                </Button>
              </div>
            </ModalWrapper>
          </Modal>
        </Container>
      )}
    </SurwayWrapper>
  );
};

export default Survey;
