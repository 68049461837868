// In insurance-provider.tsx or insurance-provider.js
export interface InsuranceProvider {
  name: string;
  value: string;
}

export const insuranceProviders: InsuranceProvider[] = [
  { name: "ALLSTATE", value: "allstate" },
  { name: "AMERICAN FAMILY", value: "american-family" },
  { name: "FARMERS INS", value: "farmers-ins" },
  { name: "GEICO", value: "geico" },
  { name: "THE GENERAL", value: "the-general" },
  { name: "Liberty Mutual", value: "liberty-mutual" },
  { name: "Nationwide", value: "nationwide" },
  { name: "Progressive", value: "progressive" },
  { name: "Root", value: "root" },
  { name: "State Farm", value: "state-farm" },
  { name: "Travelers", value: "travelers" },
  { name: "USAA", value: "usaa" },
];
