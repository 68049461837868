import styled from "styled-components";

export const HeadingWrapper = styled.div`
  .h1 {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "Poppins Medium";
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 0rem 0rem 0rem 3rem;
    @media screen and (max-width: 992px) {
      font-size: 3.813rem;
      padding: 4rem;
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      font-size: 2.813rem;
      padding: 1rem;
      width: 100%;
    }
  }
  h2 {
    color: ${(props) => props.theme.colors.black_800};
    font-family: "Poppins Semibold";
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  h4 {
    color: ${(props) => props.theme.colors.balck_900};
    font-family: "Poppins Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0px;
  }
  .h4Medium {
    color: ${(props) => props.theme.colors.purple_200};
    font-family: "SatoshiBold";
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 0rem 5rem;
    @media screen and (max-width: 992px) {
      padding: 3rem 0rem;
    }
  }
  .h4Small {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-align: start;
  }
  h5 {
    color: ${(props) => props.theme.colors.purple_0};
    font-family: "SatoshiBold";
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }
  .h5XXl {
    color: ${(props) => props.theme.colors.white_100};
    font-family: "SatoshiBold";
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    span {
      color: ${(props) => props.theme.colors.purple_100};
    }
  }
`;
