import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { HeroButtonWrapper } from "./styles";
import { HeroButtonProps } from "./utils/interfaces";

interface CustomButtonProps extends HeroButtonProps {
  icon?: React.ReactNode;
  iconPosition?: "before" | "after";
  disabled?: boolean; // Add disabled prop
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  className,
  onClick,
  style,
  to,
  icon,
  iconPosition = "before",
  disabled = false, // Default to false if not provided
}) => {
  const buttonContent = (
    <Button
      className={`${className} ${disabled ? "btn-disabled" : ""}`} // Add conditional class for disabled state
      onClick={disabled ? undefined : onClick} // Prevent onClick if disabled
      style={style}
      disabled={disabled} // Apply disabled prop to Button
    >
      <div className="button-content">
        {icon && iconPosition === "before" && (
          <span className="button-icon">{icon}</span>
        )}
        <span className="button-text">{children}</span>
        {icon && iconPosition === "after" && (
          <span className="button-icon">{icon}</span>
        )}
      </div>
    </Button>
  );

  return (
    <HeroButtonWrapper className="w-100">
      {to ? <Link to={to}>{buttonContent}</Link> : buttonContent}
    </HeroButtonWrapper>
  );
};

export default CustomButton;
