import styled from "styled-components";

export const YearContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

export const YearItem = styled.div<{ isactive: boolean }>`
  background: ${({ isactive, theme }) =>
    isactive ? theme.colors.green_1000 : theme.colors.blue_200};
  color: ${({ isactive }) => (isactive ? "white" : "#0e75bc")};
  border-radius: 4px;
  padding: 24px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  width: 103px;

  @media (max-width: 768px) {
    width: 30%;
    margin-bottom: 8px;
    justify-content: center;
    display: flex;
  }
`;
