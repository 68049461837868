import styled from "styled-components";
import { Navbar } from "react-bootstrap";

export const StyledNavbar = styled(Navbar)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #E34D4E;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ddd; /* Add bottom border to Navbar */

  &.bg-body-tertiary {
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const HeaderWrapper = styled.header`
  background-color: #fff;
  .navbar-brand {
    width: 200px;
    height: 48px;
    border-right: 1px solid #ddd;
    padding-right: 8px;
  }
`;
