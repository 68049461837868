export interface InsurancePackage {
  name: string;
}

export const InsurancePackages: InsurancePackage[] = [
  { name: "LESS THAN A YEAR" },
  { name: "1 TO 2 YEARS" },
  { name: "2 TO 3 YEARS" },
  { name: "4+ YEARS" },
];
