import React, { useState } from "react";
import { BooleanOption } from "../../../../../../src/globals/global";
import { BooleanQuestionWrapper } from "../../../../../../src/globals/global";

interface AutoInsuranceProps {
  answers: string[];
  onSelect: (answer: string) => void;
  seletedAns:string,
}

const AutoInsurance: React.FC<AutoInsuranceProps> = ({ answers, onSelect , seletedAns}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(seletedAns);

  const handleOptionClick = (answer: string) => {
    setSelectedAnswer(answer);
    onSelect(answer);
  };

  return (
    <BooleanQuestionWrapper>
      {answers.map((answer) => (
        <BooleanOption
          key={answer}
          selected={selectedAnswer === answer}
          className={selectedAnswer === answer ? 'selected':''}
          onClick={() => handleOptionClick(answer)}
        >
          {answer}
        </BooleanOption>
      ))}
    </BooleanQuestionWrapper>
  );
};

export default AutoInsurance;
