import React from "react";
import { Form } from "react-bootstrap";
import { VehicleModelsSectionWrapper, VehicleModelItem } from "./styles";

interface VehicleModelsSectionProps {
  models: string[];
  onModelSelect: (model: string) => void;
  selectedModel: string;
}

const VehicleModelsSection: React.FC<VehicleModelsSectionProps> = ({
  models,
  onModelSelect,
  selectedModel,
}) => {
  const [activeModel, setActiveModel] = React.useState<string | null>(
    selectedModel ?? ""
  );
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = event.target.value;
    if (selectedModel) {
      onModelSelect(selectedModel);
    }
  };

  // Split the models array into two parts: the first 8 items and the rest
  const topModels = models.slice(0, 8);
  const remainingModels = models.slice(8);

  return (
    <>
      <VehicleModelsSectionWrapper>
        {topModels.map((model, index) => (
          <VehicleModelItem
            isactive={model === activeModel}
            key={index}
            onClick={() => onModelSelect(model)}
          >
            {model}
          </VehicleModelItem>
        ))}
      </VehicleModelsSectionWrapper>

      {remainingModels.length > 0 && (
        <Form.Select
          aria-label="Select a model"
          className="w-100 mt-3"
          value={activeModel ?? ""}
          onChange={handleSelectChange}
        >
          <option value="">Select a model</option>
          {remainingModels.map((model, index) => (
            <option key={index} value={model}>
              {model}
            </option>
          ))}
        </Form.Select>
      )}
    </>
  );
};

export default VehicleModelsSection;
