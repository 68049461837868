import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import CustomButton from "../../../shared/Button";
import Heading from "../../../shared/Heading"; // Adjust the import path
import { Col, Row } from "react-bootstrap";
import { Autocomplete } from "@react-google-maps/api";
import InputMask from "react-input-mask";

const AddressInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .good-news {
    background: #f3f3f3;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 10px;
    font-family: "Poppins Regular";
  }
  .disclaimer {
    color: #333;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    font-family: "Poppins Regular";
  }
  .custom-dob {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid #d8d8d8;
    min-height: 72px;
    min-width: 300px;
    padding: 12px 24px;
    font-size: 30px;
    box-sizing: border-box;
  }
  .custom-dob:focus {
    border-color: ${(props) => props.theme.colors.green_1000};
    background: ${(props) => props.theme.colors.white} !important;
    outline: none; /* Optional: Removes default browser outline */
  }
  .custom-dob::placeholder {
    font-size: 16px;
    color: ${(props) => props.theme.colors.black_1000} !important;
  }


`;

const StyledInput = styled.input`
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid ${(props) => props.theme.colors.grey_300};
  color: ${(props) => props.theme.colors.black_1000} !important;
  min-height: 72px;
  min-width: 300px;
  padding: 12px 24px;
  font-size: 30px;
  box-sizing: border-box;

  &:focus {
    border-color: ${(props) => props.theme.colors.green_1000};
    background: ${(props) => props.theme.colors.white} !important;
    outline: none; /* Optional: Removes default browser outline */
  }

  &::placeholder {
    font-size: 16px;
    color: ${(props) => props.theme.colors.black_1000} !important;
  }
`;

const ButtonWrapper = styled.div`
  display: block;
  justify-content: flex-start;
  margin-top: 24px;
`;

interface AddressInfoErrors {
  street?: string;
  zip?: string;
  phone?: string;
}

interface AddressInfoProps {
  street: string;
  zip: string;
  phone: string;
  firstName: string;
  onStreetChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onZipChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (street: string, zip: string, phone: string) => void;
  errors: AddressInfoErrors;
}

const AddressInfo: React.FC<AddressInfoProps> = ({
  street,
  zip,
  phone,
  firstName,
  onStreetChange,
  onZipChange,
  onPhoneChange,
  onSubmit,
  errors,
}) => {
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const autocompleteRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (window.google && autocompleteRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ["address"] }
      );
      setAutocomplete(autocomplete);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const address = place.formatted_address;
          // Update state or perform other actions with the address
          onStreetChange({
            target: { value: address },
          } as React.ChangeEvent<HTMLInputElement>);
        }
      });
    }
  }, []);

  return (
    <AddressInfoWrapper className="w-100">
      <Heading level={2}>
        {firstName}, Good News! We’ve found matches for you.
      </Heading>
      <div className="good-news">
        Providing the address where your car is parked ensures you receive the
        best coverage and price
      </div>

      <Row className="justify-between mb-3">
        <Col md={12}>
          <StyledInput
            type="text"
            className="w-100"
            ref={autocompleteRef}
            value={street}
            onChange={onStreetChange}
            placeholder="Street Address"
          />
          {errors.street && (
            <div className="error_message">{errors.street}</div>
          )}
        </Col>
      </Row>
      <Row className="justify-between mb-3">
        <Col md={12}>
          <StyledInput
            className="w-100"
            value={zip}
            type="number"
            onChange={onZipChange}
            placeholder="Zip Code"
          />
          {errors.zip && <div className="error_message">{errors.zip}</div>}
        </Col>
      </Row>
      <Row className="justify-between mb-3">
        <Col md={12}>
          <InputMask
            mask="999 999-9999"
            placeholder="Phone Number"
            className="custom-dob w-100"
            value={phone}
            onChange={onPhoneChange}
            type="tel"
            alwaysShowMask={false}
            maskChar={null}
            maskPlaceholder={null}
          />
          {errors.phone && <div className="error_message">{errors.phone}</div>}
        </Col>
      </Row>
      <ButtonWrapper>
        <CustomButton
          className="secondary p-4 text-center d-block"
          onClick={() => onSubmit(street, zip, phone)}
        >
          Get My Auto And Home Quotes{" "}
        </CustomButton>

        <CustomButton
          className="secondary p-4 text-center d-block mt-4"
          style={{
            backgroundColor: "transparent",
            border: "3px solid #07b26e",
            color: "#07b26e",
          }}
          onClick={() => onSubmit(street, zip, phone)}
        >
          Get My Auto Quotes Only
        </CustomButton>
      </ButtonWrapper>
      <div className="disclaimer">
        By clicking "Get My Auto Quotes" I provide my electronic signature and
        express written consent to telemarketing calls, text messages, emails,
        and postal mail from this Web site, our marketing and re-marketing
        network, and up to eight insurance companies or their affiliates or
        representatives at the phone number (including wireless number), email
        address, and postal address provided by me. I consent to calls and text
        messages transmitting insurance quotes, or seeking related additional
        information from me, using an Automatic Telephone Dialing System or
        prerecorded or artificial voices. I consent that my signature is not a
        condition of purchasing any property, goods, or services and that I may
        revoke my consent at any time. Additionally, by clicking "Get My Auto
        Quotes", I acknowledge that I have read, understand, and agree to this
        Web site’s Privacy Policy and Terms of Use I am directing this Web site
        to share my information to the providers in our network for the purpose
        of providing me with information about their financial services and
        products.
      </div>
    </AddressInfoWrapper>
  );
};

export default AddressInfo;
