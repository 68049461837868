import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import footerImg from "../../../../assets/images/footer-img.svg";
import slide1 from "../../../../assets/images/slide1.jpg";
import slide2 from "../../../../assets/images/slide2.jpg";
import slide3 from "../../../../assets/images/slide1.jpg";
import commonimage from "../../../../assets/images/iconmonstr-quote-3.png";
import shield from "../../../../assets/images/blue-shield.svg";
import trustAccredited from "../../../../assets/images/trustAccredited.svg";
import { FooterWrapper } from "./styles";
import Carousel from 'react-bootstrap/Carousel';
//import { Paragraph } from "src/views/pages/home/styles";


const Footer = () => {
  return (
<>
<Container className="caruaosel_slider">
    <Carousel>
      <Carousel.Item className="text-center">
        <Image src={commonimage} className="img-fluid quote_img"/>
        <p className="my_para">With QuickAutoQuotes, I saved enough to renovate my kitchen. It's unbelievable how much you can save!</p>
        <Image src={slide1} className="img-fluid profile_doe"/>
        <h4 className="profile_title">Sarah L.</h4>
      </Carousel.Item>

      <Carousel.Item className="text-center">
        <Image src={commonimage} className="img-fluid quote_img"/>
        <p className="my_para">Thanks to QuickAutoQuotes, I saved $300 on my annual premium. This tool is a lifesaver!</p>
        <Image src={slide2} className="img-fluid profile_doe"/>
        <h4 className="profile_title">John D.</h4>
      </Carousel.Item>
    
      <Carousel.Item className="text-center">
        <Image src={commonimage} className="img-fluid quote_img"/>
        <p className="my_para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat fuga dolore soluta. Sunt provident saepe est sit deserunt fuga labore quibusdam. Optio non, autem illo inventore saepe dignissimos earum sit?</p>
        <Image src={slide3} className="img-fluid profile_doe"/>
        <h4 className="profile_title">Jhon doe</h4>
      </Carousel.Item>
    </Carousel>
  </Container>

    <FooterWrapper>
        <Container>
          {/* <Row className="mt-5">
            <Col md={12}>
              <div className="text-center d-flex justify-content-center gap-3 align-items-center">
                <Image src={shield} style={{ width: "20px" }} />
                <h3> Free quotes, secure form, competitive offers.</h3>
                <Image src={trustAccredited} style={{ width: "140px" }} />
              </div>
            </Col>
          </Row> */}
          <Row className="mt-5">
            <Col md={12}>
              <h3 className="text-center">
                Discover quotes from over 80 providers including:
              </h3>
              <div className="text-center mt-4">
                <Image src={footerImg} fluid />
              </div>
            </Col>
          </Row>
        </Container>

        <footer className="mt-5 copyright_footer">
          <Container>
            <div className="d-md-flex justify-content-between ">
              <div>
                <p className="copyright_para">© 2024 QuoteWizard</p>
              </div>
              <div className="d-md-flex gap-3 my_footer_link">
                <Link to="#">Contact</Link>
                <Link to="#">Privacy Policy </Link>
                <Link to="#">Terms of Use </Link>
                <Link to="#"> Online Tracking </Link>
                <Link to="#"> Accessibility Statement </Link>
              </div>
            </div>
          </Container>
        </footer>
      </FooterWrapper></>
  );
};

export default Footer;
