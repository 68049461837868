import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomButton from "../../../shared/Button";
import InputMask from "react-input-mask";

// Styled wrapper for the DateOfBirth component
const DateOfBirthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .custom-dob {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid #d8d8d8;
    min-height: 72px;
    min-width: 300px;
    padding: 12px 24px;
    font-size: 30px;
    box-sizing: border-box;
  }

  .error-message {
    background-color: orange;
    clear: both;
    color: #fff;
    display: block;
    width: 100%;
    font-weight: 700;
    padding: 5px 10px 5px 5px;
    text-align: center;
    position: relative;
    text-shadow: none;
    transition: 0.3s;
  }
`;

// Styled input for the DatePicker component
const StyledInput = styled.input`
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid ${(props) => props.theme.colors.grey_300};
  color: ${(props) => props.theme.colors.black_1000} !important;
  min-height: 72px;
  min-width: 300px;
  padding: 12px 24px;
  font-size: 30px;
  box-sizing: border-box;

  &:focus {
    border-color: ${(props) => props.theme.colors.green_1000};
    background: ${(props) => props.theme.colors.white} !important;
    outline: none; /* Optional: Removes default browser outline */
  }

  &::placeholder {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins Bold", sans-serif;
    color: ${(props) => props.theme.colors.black_1000} !important;
  }
`;

// Styled button wrapper for alignment
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
`;

// Props for DateOfBirth component
interface DateOfBirthProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onSubmit: (date: Date | null) => void;
}

// DateOfBirth component
const DateOfBirth: React.FC<DateOfBirthProps> = ({
  selectedDate,
  onDateChange,
  onSubmit,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = formatDate(selectedDate);
      setInputValue(formattedDate);
    }
  }, [selectedDate]);

  const formatDate = (date: Date): string => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Handle date input change from InputMask
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    if (datePattern.test(value)) {
      const [month, day, year] = value.split("/").map(Number);
      const isValidDate =
        month >= 1 && month <= 12 && day >= 1 && day <= 31 && year >= 1900;
      if (isValidDate) {
        const date = new Date(year, month - 1, day);
        const today = new Date();
        const age = today.getFullYear() - date.getFullYear();
        const ageCheck =
          age > 15 ||
          (age === 15 &&
            (today.getMonth() > date.getMonth() ||
              (today.getMonth() === date.getMonth() &&
                today.getDate() >= date.getDate())));
        if (ageCheck) {
          onDateChange(date);
          setError(null);
        } else {
          onDateChange(null);
          setError(
            "Please enter a valid date. You must be at least 15 years old."
          );
        }
      } else {
        onDateChange(null);
        setError("Please enter a valid date in MM/DD/YYYY format.");
      }
    } else {
      onDateChange(null);
      setError("Please enter a valid date in MM/DD/YYYY format.");
    }
  };

  return (
    <DateOfBirthWrapper className="w-100">
      <InputMask
        mask="99/99/9999"
        placeholder="Enter birthdate"
        className="custom-dob"
        onChange={handleInputChange}
        value={inputValue}
        type="tel"
      />
      {error && <div className="error-message">{error}</div>}
      <ButtonWrapper>
        <CustomButton
          className="primary w-100"
          onClick={() => onSubmit(selectedDate)}
        >
          Submit
        </CustomButton>
      </ButtonWrapper>
    </DateOfBirthWrapper>
  );
};

export default DateOfBirth;
