import React from "react";
import { EducationSectionWrapper, EducationItem } from "./styles";

interface EducationSectionProps {
  items: string[]; // Changed from models to items
  onItemSelect: (item: string) => void;
  selectedEducation:string; // Changed from onModelSelect to onItemSelect
}

const EducationSection: React.FC<EducationSectionProps> = ({
  items,
  onItemSelect,
  selectedEducation,
}) => {
  return (
    <EducationSectionWrapper>
      {items.map((item, index) => (
        <EducationItem className={selectedEducation ==item ?'selected':'' } key={index} onClick={() => onItemSelect(item)}>
          {item}
        </EducationItem>
      ))}
    </EducationSectionWrapper>
  );
};

export default EducationSection;
