import styled from "styled-components";

export const FooterWrapper = styled.header`
  border-top: 1px dashed #eaeaea;
  h3 {
    color: #000000;
    font-family: "Poppins Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0px;
  }
  footer {
    border-top: 1px dashed #eaeaea;
    a {
      font-family: "Poppins Medium";
      color: #6a6a6a;
    }
    p {
      font-family: "Poppins Regular";
    }
  }
    @media(max-width: 481px){
  .my_footer_link, .copyright_para {
    text-align: center;
  }
  .my_footer_link a {
      padding: 0px 5px;
      border-right: 1px solid #000;
  }
}
`;
