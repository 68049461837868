import React, { useState } from "react";
import { BooleanQuestionWrapper , BooleanOption } from "../../../../../globals/global";
interface OtherVehicle {
  // Renamed the interface to OtherVehicle
  answers: string[];
  onSelect: (answer: string) => void;
  selectedAns:string;
}

const OtherVehicle: React.FC<OtherVehicle> = ({
  answers,
  onSelect,
  selectedAns,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(selectedAns);

  const handleOptionClick = (answer: string) => {
    setSelectedAnswer(answer);
    onSelect(answer);
  };

  return (
    <BooleanQuestionWrapper>
      {answers.map((answer) => (
        <BooleanOption
          key={answer}
          selected={selectedAnswer === answer}
          onClick={() => handleOptionClick(answer)}
          className={selectedAnswer === answer ? 'selected':''}
        >
          {answer}
        </BooleanOption>
      ))}
    </BooleanQuestionWrapper>
  );
};

export default OtherVehicle; // Updated the export to Married
